export interface styles {
  mainMenuFontColour: string
  logoFontColour: string
}

export const state = (): styles => ({
  mainMenuFontColour: '',
  logoFontColour: ''
})

export const mutations = {
  setLogoFontColour(state: styles, text: string) {
    state.logoFontColour = text
  },
  setMainMenuFontColour(state: styles, text: string) {
    state.mainMenuFontColour = text
  },
}
