









import { Component, Vue, Watch } from 'nuxt-property-decorator'
import Header from '~/components/provider/Header.vue'

@Component({
  components: { Header }
})
export default class ProviderLayout extends Vue {
  mounted () {
    this.trackPageName()
  }

  @Watch('$route', { deep: true })
  trackPageName () {
    (window as any).analytics.page('EP Portal ' + this.pageName)
  }

  get pageName () {
    switch (this.$nuxt.$route.name) {
      case 'education-provider-company':
        return 'Company'
      case 'education-provider-dashboard':
        return 'Dashboard'
      case 'education-provider-faqs':
        return 'FAQs'
      case 'education-provider-schedule':
        return 'Schedule'
      case 'education-provider-schedule-list':
        return 'Schedule List'
      case 'education-provider-schedule-calendar':
        return 'Schedule Calendar'
      case 'education-provider-schedule-course-id':
        return 'Courses Detail'
      case 'education-provider-schedule-session-id':
        return 'Session Detail'
      case 'education-provider-teacher':
        return 'Teacher'
      default:
        return this.$nuxt.$route.name
    }
  }
}
