// eslint-disable-next-line import/named
import { SweetAlertPosition } from 'sweetalert2'

export interface ISelect {
  value?: string
  label?: string
  isChecked?: boolean
}

export interface ITimingOption {
  dayOfWeek?: ISelect[]
  dayOfWeekRange?: ISelect[]
  timeOfDay?: ISelect[]
  startingMonth?: ISelect[]
}

export interface IDropdownOption extends ISelect {
  image?: string
  numOfValidValue?: number
  hasSubMenuButton?: boolean
  minAge?: number
  maxAge?: number
  amount?: number
  subOptions?: IDropdownOption[]
  data?: any
}

export interface IDropdown extends ISelect {
  isTimeSelect?: boolean
  hasSearchBox?: boolean
  timing?: ITimingOption
  options?: IDropdownOption[]
  dayOfWeek?: IDropdownOption[]
}

export interface SelectOption {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
  selectedAll?: boolean;
}
export interface ToastConfig {
  position?: SweetAlertPosition
  icon?: 'warning' | 'error' | 'success' | 'info' | 'question'
  title?: string
  text?: string
  timer?: number
}

export interface CalendarEvents {
  start?: any
  end?: any
  event?: any,
  date?: any,
  nativeEvent?: any
  name?: string
  timed?: boolean
  color?: string
}

export interface DataTableOptionProps {
  page: number,
  itemsPerPage: number
}

export interface DataTableHeaderProps {
  text: string,
  value: string,
  align?: string, // 'start' | 'center' | 'end'
  sortable?: boolean,
  filterable?: boolean,
  groupable?: boolean,
  divider?: boolean,
  class?: string | string[],
  cellClass?: string | string[],
  width?: string | number,
  filter?: (value: any, search: string, item: any) => boolean,
  sort?: (a: any, b: any) => number
}

export interface DataTableFooterProps {
  'items-per-page-text'?: string,
  'items-per-page-options'?: number[]
}

export interface CustomTableOptionsDto {
  headerOptions?: DataTableHeaderProps[]
  footerOptions?: DataTableFooterProps
  options?: DataTableOptionProps
  totalCount?: number | string
  items?: any[]
  haveSearchBox?: boolean
  haveDatePicker?: boolean
  noDataText?: string
  hasMissingTeacherFilterBtn?: boolean
}

export interface CustomMultiSelectContent {
  type: string,
  title: string,
  hasSearchBox: boolean,
  options: SelectOption[]
}

export interface FilterForm {
  subjecttypes?: string[]
  subjectareas?: string[]
  subjects?: string[]
  ages?: string[]
  gradelevels?: string[]
  educationproviders?: string[]
  educationProviderSlugs?: string[]
  months?: string[]
  days?: string[]
  times?: string[]
  coursestatuses?: string[]
  courseformats?: string[]
  courseperiods?: string[]
  coursetypes?: string[]
  searchCoursesString?: string,
  trialavailable?: string[]
}

export interface CourseDisplayConfigDto {
  showAvatarProvider: boolean
  showCourseImage: boolean
  showTeacherName: boolean
  showStatus: boolean
  showCourseInstanceList: boolean
  showBookmark: boolean
  showProviderName: boolean,
  showSubjectName: boolean,
  showCourseAvailable: boolean
  showCourseDate: boolean
}

export const DISPLAY_COURSE_LIST_CARD_CONFIG: CourseDisplayConfigDto = {
  showStatus: true,
  showAvatarProvider: true,
  showCourseImage: false,
  showCourseInstanceList: false,
  showTeacherName: false,
  showBookmark: true,
  showProviderName: true,
  showSubjectName: false,
  showCourseAvailable: false,
  showCourseDate: true
}

export const DISPLAY_EP_COURSE_LIST_CARD_CONFIG: CourseDisplayConfigDto = {
  showStatus: false,
  showAvatarProvider: false,
  showCourseImage: true,
  showCourseInstanceList: true,
  showTeacherName: true,
  showBookmark: false,
  showProviderName: false,
  showSubjectName: true,
  showCourseAvailable: true,
  showCourseDate: false
}
export interface QueryParamDto {
  key: string,
  value: string | string[] | boolean
}

export interface MenuDto {
  label: string
  path: string
  isExpanded?: boolean
  subMenu?: {
    label: string
    path: string
  }[]
}

export interface ReferralParamsDto {
  earnedCredit?: number | string
  minPurchaseAmount?: number | string
}

export enum FromPage {
  AtSchool = 'at_school',
  Agora = 'agora'
}

export interface AtSchoolTabDto {
  name?: string
  href: string
  current?: boolean
  image?: string
  slug?: string
}

export interface CourseSchemaDto {
  startDate: string
  endDate: string
  teacher: {
    fullName: string
    firstName: string
    lastName: string
  }
}

export enum FaqType {
  MainFaq='main-faq',
  AtSchool='faqs-at-school',
  StudentCare='student-care',
  EarlyYears='early-years',
  EventHosting='event-hosting'
}

export interface ISubscriptionLineData {
  id: string
  description: string
  quantity: string
  unitPrice: string
  nett: string
  gstPercent: string
  gstAmount: string
  discountInput: string
  total: string
}

export interface ISubscriptionData {
  from: {
    id: string
    shortName: string
    legalName: string
    addressToArray: string[]
    uen: string
    image: string
  },
  to: {
    id: string
    fullName: string
    email: string
  }
  status: {
    label: string
    customStyles: string
  },
  notes: string
  referenceId: string
  sentDate: string
  collectionDate: string
  subscriptionLines: ISubscriptionLineData[]
  subTotal: string
  discount: string
  gst: string
  total: string
}
