export const COURSE_FILTER_PARAMS: string[] = [
  'courseperiods',
  'educationproviders',
  'subjecttypes',
  'subjectareas',
  'subjects',
  'ages',
  'gradelevels',
  'months',
  'days',
  'times',
  'coursestatuses',
  'courseformats',
  'coursetypes',
  'allowtrialsession',
  'v'
]

export enum COURSE_FILTER_PARAM_ENUM {
  GradeLevel = 'gradelevels',
  Subject = 'subjects',
  SubjectArea = 'subjectareas',
  SubjectType = 'subjecttypes',
  Time = 'times',
  Day = 'days',
  Month = 'months',
  CourseType = 'coursetypes',
  CoursePeriod = 'courseperiods',
  EducationProvider = 'educationproviders',
  AllowTrialSession = 'allowtrialsession'
}

export const WEEKDAYS = [
  '1',
  '2',
  '3',
  '4',
  '5'
]

export const WEEKENDS = [
  '6',
  '7'
]
