import { Middleware } from '@nuxt/types'
import { canAccessEPPortal } from "~/utils/helpers";

const authenticated: Middleware = (context) => {
  const user = context.store.state.user
  const path = context.route.path
  const isSecretPage = path.includes('education-provider')
  const isAccessEPPortal = canAccessEPPortal(user)

  if (user && isSecretPage) {
    if (isAccessEPPortal) {
      return
    }

    context.redirect('/')
    return
  }

  if (user && !isSecretPage && isAccessEPPortal) {
    context.redirect('/education-provider')
    return
  }

  if (!user && isSecretPage) {
    context.redirect('/sign-in')
  }
}

export default authenticated
