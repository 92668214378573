import Cookies from 'js-cookie'
import { parse as parseCookie } from 'cookie'
import * as router from 'vue-router'
import { remove, findIndex, cloneDeep } from 'lodash-es'
import { ShoppingCartDto, UTMParamDto } from '~/apis'
import { ReferralParamsDto, IDropdown, AtSchoolTabDto } from '~/types/common'

export interface UserInfo {
  fullname: String,
  imageUrl: string
}

export interface CartItem {
  cartItemId: string,
  courseId?: string,
  courseName? :string,
  instanceId?: string,
  agoraId? :string,
  userId?: string,
  courseDuration?: number | null,
  quantity: number,
  cartType: 'course' | 'agora_care',
  timeStartDisplay?: Date | string,
  timeEndDisplay?: Date | string,
  cartItemCreatedAt: Date | string
}

export interface BadgeItems {
  cart?: number,
  message?: number,
  notify?: number
}

export interface ShoppingCart {
  userId:string,
  badgeItems: BadgeItems,
  cartList: CartItem[]
}

interface TempCredential {
  email: string
  tempPassword: string
}

interface ISelectedCourseInstance {
  courseSlug: string
  venueId: string
}
interface contentSchool {
  title?: string,
  image?: string,
  slug?: string
}

export interface ICommonState {
  authenticated: boolean,
  userInfo?: any,
  intercom?: any,
  latestLocation?: router.Location | null,
  shoppingCart: ShoppingCart,
  messageUnreadCount: number,
  tempCredential: TempCredential | null
  user: UserInfo | null,
  theSpaceContent: any,
  homePageContent: any
  blogDetailContent: any
  newSignUp: boolean
  promoCode: string
  utmParams: UTMParamDto[],
  referralParams: ReferralParamsDto,
  courseFilterData: IDropdown[]
  currentViewFor: 'agora' | 'at_school'
  schoolsHeader: AtSchoolTabDto[]
  selectedCourseInstance: ISelectedCourseInstance,
  contentSchool: contentSchool,
  schoolSlug?: string,
  venueEmail?: string
  courseListFullPath?: string
}

export const state = (): ICommonState => ({
  authenticated: false,
  userInfo: null,
  latestLocation: null,
  shoppingCart: { userId: '', badgeItems: { cart: 0, message: 0, notify: 0 }, cartList: [] },
  messageUnreadCount: 0,
  tempCredential: null,
  user: null,
  theSpaceContent: null,
  blogDetailContent: null,
  homePageContent: null,
  newSignUp: false,
  promoCode: '',
  utmParams: [],
  referralParams: {},
  courseFilterData: [],
  currentViewFor: 'agora',
  schoolsHeader: [],
  selectedCourseInstance: { courseSlug: '', venueId: '' },
  contentSchool: { image: '', slug: '', title: '' },
  schoolSlug: '',
  venueEmail: ''
})

export const getters = {
  getAuthenticated: (state: ICommonState) => {
    return state.authenticated
  },
  getUser: (state: ICommonState) => {
    return state
  },
  getCurrentViewFor: (state: ICommonState) => {
    return state.currentViewFor
  },
  getCourseListFullPath: (state: ICommonState) => {
    return state.courseListFullPath
  }
}

export const mutations = {
  setUser (state: ICommonState, data: any) {
    state.user = data
  },
  setAuthentication (state: ICommonState, authenticationInfo: ICommonState) {
    state.authenticated = authenticationInfo.authenticated
    state.user = authenticationInfo.user
  },
  setLatestLocation (state: ICommonState, location: router.Location) {
    state.latestLocation = location
  },
  setIntercom (state: ICommonState, intercom: any) {
    state.intercom = intercom
  },
  addCartItem (state: ICommonState, cart: CartItem) {
    const temp = cloneDeep(state.shoppingCart.cartList)
    temp.push(cart)
    state.shoppingCart.cartList = [...temp]
    state.shoppingCart.badgeItems.cart = temp.length
  },
  removeCartItem (state: ICommonState, cart: ShoppingCartDto) {
    const temp = cloneDeep(state.shoppingCart.cartList)
    remove(temp, function (o) {
      return o.cartItemId === cart.cartItemId
    })
    state.shoppingCart.cartList = [...temp]
    state.shoppingCart.badgeItems.cart = temp.length
  },
  updateCartItem (state: ICommonState, cart: {infoUpdate: ShoppingCartDto, userId:string}) {
    const temp = cloneDeep(state.shoppingCart.cartList)
    const cartUpdate = findIndex(temp, function (o) { return o.cartItemId === cart.infoUpdate.cartItemId })
    const cartInfo = { ...cart.infoUpdate }
    if (cartUpdate >= 0) {
      temp[cartUpdate] = {
        cartItemId: cartInfo.cartItemId!,
        courseId: cartInfo.courseId,
        instanceId: cartInfo.instanceId,
        userId: cart.userId,
        quantity: cartInfo.quantity!,
        courseName: cartInfo.courseName,
        courseDuration: cartInfo.courseDuration,
        timeStartDisplay: cartInfo.timeStartDisplay,
        timeEndDisplay: cartInfo.timeEndDisplay,
        cartType: 'course',
        cartItemCreatedAt: cart.infoUpdate.cartItemCreatedAt!
      }
      state.shoppingCart.cartList = [...temp]
    }
  },
  setBadgeItems (state: ICommonState, badgeItems: BadgeItems) {
    state.shoppingCart.badgeItems = { ...state.shoppingCart.badgeItems, ...badgeItems }
  },
  cleanShoppingCart (state: ICommonState, userId:string) {
    state.shoppingCart = {
      userId,
      badgeItems: { ...state.shoppingCart.badgeItems, cart: 0 },
      cartList: []
    }
  },
  setMessageUnreadCount (state: ICommonState, messageUnreadCount: number) {
    state.messageUnreadCount = messageUnreadCount
  },
  setTempCredential (state: ICommonState, tempCredential: TempCredential) {
    state.tempCredential = tempCredential
  },
  setNewSignUp (state: ICommonState, data: boolean) {
    state.newSignUp = data
  },
  setPromoCode (state: ICommonState, promoCode: string) {
    state.promoCode = promoCode
  },
  setUTMParams (state: ICommonState, utmParams: UTMParamDto[]) {
    state.utmParams = utmParams
  },
  setReferralParams (state: ICommonState, referralParams: ReferralParamsDto) {
    state.referralParams = referralParams
  },
  setCourseFilterData (state: ICommonState, courseFilterData: IDropdown[]) {
    state.courseFilterData = courseFilterData
  },
  setCurrentViewFor (state: ICommonState, currentView: 'agora' | 'at_school') {
    state.currentViewFor = currentView
  },
  setSchoolsHeader (state: ICommonState, schoolsHeader: AtSchoolTabDto[]) {
    state.schoolsHeader = schoolsHeader
  },
  setSelectedCourseInstance (state: ICommonState, selectedCourseInstance: ISelectedCourseInstance) {
    state.selectedCourseInstance = selectedCourseInstance
  },
  setContentSchool (state: ICommonState, contentSchool: contentSchool) {
    state.contentSchool = contentSchool
  },
  setSchoolSlug (state: ICommonState, schoolSlug: string) {
    state.schoolSlug = schoolSlug
  },
  setVenueEmail (state: ICommonState, venueEmail: string) {
    state.venueEmail = venueEmail
  },
  setCourseListFullPath (state: ICommonState, fullPath: string) {
    state.courseListFullPath = fullPath
    Cookies.set('courseListFullPath', fullPath)
  }
}

export const actions = {
  async nuxtServerInit (action: any, context: any) {
    const axios = context.app.$axios
    const headers = context.req.headers

    // Fix local error: Authorization of undefined
    axios.onRequest((config: any) => {
      if (!config.headers.common) {
        config.headers.common = {}
      }
    })

    if (headers && headers.cookie) {
      const parsedCookie = parseCookie(headers.cookie as string)
      if (parsedCookie['auth._token.local'] !== 'false') {
        const res = await axios.$get(`${process.env.VUE_APP_API_URL}/auth/current-user`)
        if (res.sub) {
          action.commit('setAuthentication', { authenticated: true, user: res })
        } else {
          action.commit('setAuthentication', { authenticated: false, user: null })
        }
      } else {
        action.commit('setAuthentication', { authenticated: false, user: null })
      }
    } else {
      action.commit('setAuthentication', { authenticated: false, user: null })
    }
  }
}
