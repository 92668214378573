import VueLazyload from 'vue-lazyload'
import VTooltip from 'v-tooltip'
import Vue from 'vue'

Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: require('../assets/spinner.svg'),
  // loading: require('../assets/spinner.svg'),
  attempt: 1,
  // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
  listenEvents: ['scroll']
})

Vue.use(VTooltip)
