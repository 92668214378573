export enum BaseFilterSubject {
    All = 'all',
    Tuition = 'tuition',
    Enrichment = 'enrichment'
}
export interface ISubjectFilter {
    baseFilter: BaseFilterSubject;
    subjectArea?: string;
    ageGroup?: string;
}
export interface Subject {
    id?: string;
    image: string;
    name: string;
    available: string;
    price: string;
}
