import axios from 'axios'
import moment from 'moment'
import { showToast } from './functions'
import { AddToMediaRequest, GetCurrentUserResponse, GetPresignedPutURLRequest, MediaDto, UploadsApi } from '~/apis'

export const handleDownloadSingleFile = async (url: string, fileName: string, fileType = 'application/pdf') => {
  const response = await axios.get(url, { responseType: 'blob' })
  const blob = new Blob([response.data], { type: fileType })
  const elementLink = document.createElement('a')
  elementLink.href = URL.createObjectURL(blob)
  elementLink.download = fileName
  elementLink.click()
  URL.revokeObjectURL(elementLink.href)
}

export async function handleUploadFile (file: File, getPresignedPutURLRequest: GetPresignedPutURLRequest): Promise<MediaDto[]> {
  const { data: { data: getPresignedPutURLResponse } } = await new UploadsApi().getPresignedPutURL(getPresignedPutURLRequest)

  const { preSignedPutURL, fileAccessURL } = getPresignedPutURLResponse

  await axios.put(preSignedPutURL, file)
    .catch((err) => {
      showToast({
        icon: 'error',
        text: 'File upload failed',
        title: 'Failed'
      })
      throw err
    })

  const addToMediaRequest: AddToMediaRequest = {
    files: [
      {
        name: file.name,
        size: file.size,
        type: file.type,
        url: fileAccessURL
      }
    ]
  }
  const { data: { data: addToMediaResponse } } = await new UploadsApi().addToMedia(addToMediaRequest)

  return addToMediaResponse
}

export const canAccessEPPortal = (userInfo: GetCurrentUserResponse) => {
  const epStatus = userInfo?.educationProviderStatus
  const userType = userInfo?.userType
  const typeCanAccessEPPortal = userType && ['ep_admin', 'ep_admin_teacher', 'teacher'].includes(userType)
  return userInfo?.enableEpPortalAccess && epStatus === 'active' && typeCanAccessEPPortal
}

export function getMomentFormatterHelper (format: string = 'DD/MM/yyyy') {
  return {
    stringify: (date?: Date) => {
      return date ? moment(date).format(format) : ''
    },
    parse: (value?: string) => {
      return value ? moment(value, format).toDate() : null
    }
  }
}
