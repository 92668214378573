



























import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { Context } from '@nuxt/types'
import YellowPanel from '../components/campaigns/YellowPanel.vue'
import CustomDialog from '~/components/shared/CustomDialog.vue'
import { NotificationsApi, ShoppingCartApi, StrapiApi, StaticPageMetaKeywordDto, HomePageContentDto } from '~/apis'
import Footer from '~/components/static-pages/Footer.vue'
import NotifyEmail from '@/components/static-pages/NotifyEmail.vue'
import { FromPage } from '~/types'
import { convertVariableSqlToText } from '~/utils/functions'

@Component({
  middleware: ['authenticated'],
  components: {
    Footer,
    CustomDialog,
    YellowPanel,
    NotifyEmail
  },
  asyncData: async (context: Context) => {
    const { data: { data } } = await new StrapiApi().staticPageMetaKeywords()
    const metaKeyWords = data

    let keywords = ''
    const path = context.route.path
    const metaKeywordsByPath = metaKeyWords.find((item: StaticPageMetaKeywordDto) => path.includes(item.url!))
    if (metaKeywordsByPath) {
      keywords = metaKeywordsByPath.keywords || ''
    }

    return {
      keywords
    }
  }
})
export default class DefaultLayout extends Vue {
  metaKeyWords: StaticPageMetaKeywordDto[] = []
  notifyContents: HomePageContentDto = {
    featureList: [],
    sliderList: [],
    feedbackList: [],
    meta: {}
  }

  index: number = 0
  keywords: string = ''

  get isHomePage () {
    return this.$route.name === 'index'
  }

  get isProfilePage () {
    return (this.$route.path.includes('/profile'))
  }

  get isLoggedIn () {
    return this.$store.state.authenticated
  }

  get isAtSchoolPage () {
    return this.$store.state.currentViewFor === FromPage.AtSchool
  }

  @Watch('$route', { deep: true, immediate: true })
  onRouteChanged () {
    this.trackPageName()
  }

  head () {
    const canonicalUrl = `${process.env.REDIRECTURI}${this.$route.path}`.replace(/\/$/, '')

    // ignore course detail and blog pages
    if (!this.$route.path.includes('courses') && this.$route.path.split('/').length > 1 && !this.$route.path.includes('blog') &&
      this.$route.name !== 'schoolSlug-courseSlug-referenceId'
    ) {
      return {
        meta: [
          { hid: 'keywords', name: 'keywords', content: `${this.keywords}` },
          {
            hid: 'og:image',
            property: 'og:image',
            content: 'https://strapi-agora.s3.ap-southeast-1.amazonaws.com/homepage_term_4_640x458_4deea2ecd3.png'
          }
        ],
        link: [
          { rel: 'canonical', href: canonicalUrl }
        ]
      }
    }
  }

  mounted () {
    document.body.classList.add('overflow-y-scroll')

    if (this.isParent) {
      this.initShoppingCart()
      this.getNotifications()
    }
    this.trackPageName()
    this.getNotifyHomePage()
  }

  beforeDestroy () {
    document.body.classList.remove('overflow-y-scroll')
  }

  trackPageName () {
    if (!process.server) {
      window.scrollTo(0, 0)
    }
    if (this.isAtSchoolPage) {
      this.setSchoolContent()
    }
  }

  get state () {
    return this.$store.state
  }

  get authenticated () {
    return this.state.authenticated
  }

  get shoppingCart () {
    return this.state.shoppingCart
  }

  get isParent () {
    return this.state.user && this.state.user.userType === 'parent'
  }

  get userInfo () {
    if (!this.state.user) {
      return null
    }
    return {
      id: this.state.user.sub,
      email: this.state.user.email,
      fullname: this.state.user.fullName,
      phoneNumber: this.state.user.phoneNumber
    }
  }

  get pageName () {
    switch (this.$nuxt.$route.name) {
      case 'about':
        return 'About'
      case 'agora-care':
        return 'Agora Care'
      case 'change-password':
        return 'Change Password'
      case 'conversation':
        return 'Conversation'
      case 'course-confirmation':
        return 'Course Confirmation'
      case 'courses-id':
        return 'Courses'
      case 'ep-enquiry-form':
        return 'EP Enquiry Form'
      case 'faqs':
        return 'FAQs'
      case 'home':
        return 'Home'
      case 'notify':
        return 'Notify'
      case 'payment-courseInstanceId':
        return 'Payment Course Instance'
      case 'payment-payment-successful':
        return 'Payment Successfully'
      case 'policy':
        return 'Policy'
      case 'profile-agora-usage':
        return 'Profile Agora Usage'
      case 'profile-bookmarked-courses':
        return 'Profile Bookmark'
      case 'profile-children-list':
        return 'Profile Children List'
      case 'profile-payment':
        return 'Profile Payment'
      case 'profile-your-course-id':
        return 'Profile Your Course'
      case 'profile-campaigns':
        return 'Profile Campaigns'
      case 'schedule-calendar':
        return 'Schedule Calendar'
      case 'schedule-list':
        return 'Schedule List'
      case 'shopping-cart':
        return 'Shopping Cart'
      case 'subjects':
        return 'Subjects'
      case 'subjects-id':
        return 'Subjects Details'
      case 'terms-and-conditions':
        return 'Terms And Conditions'
      case 'the-space':
        return 'The Space'
      case 'products':
        return 'Products'
      case 'morning-pass':
        return 'Morning Pass'
      case 'host-an-event-with-us':
        return 'Celebrations & Events'
      case 'student-care':
        return 'Student Care'
      case 'preschool-playschool':
        return 'Preschool & Playschool'
      default:
        return convertVariableSqlToText(this.$nuxt.$route.name || '')
    }
  }

  async initShoppingCart () {
    if (this.authenticated) {
      if (this.isParent) {
        const { data } = await new ShoppingCartApi().getBadgeShoppingCart()
        this.$store.commit('setBadgeItems', { cart: data })
      } else {
        this.$store.commit('setBadgeItems', { cart: 0 })
      }
    }
  }

  async getNotifications () {
    let unreadNotify = 0
    if (this.authenticated) {
      if (this.isParent) {
        const { data } = (await new NotificationsApi().getNotifications(['on_prem', 'attendance', 'payment', 'reminder', 'expire', 'withdraw'])).data
        unreadNotify = data.unreadNotify
      }
    }
    this.$store.commit('setBadgeItems', { notify: unreadNotify })
  }

  async getNotifyHomePage () {
    const { data: { data: homepageContents } } = await new StrapiApi().getHomepageContent()
    this.notifyContents = homepageContents
  }

  setSchoolContent () {
    const schoolSlug = this.$route.params.schoolSlug
    if (schoolSlug) {
      const schoolContent = this.$store.state.schoolsHeader.find((val: any) => val.href.includes(schoolSlug))
      this.$store.commit('setContentSchool', schoolContent)
    }
  }
}
