import { Middleware } from '@nuxt/types'
import mixpanel from '~/plugins/mixpanel'

const tracker: Middleware = (_context) => {
  if (process.env.NODE_ENV !== 'local') {
    mixpanel.track_pageview()
  }
}

export default tracker
