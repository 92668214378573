import { Middleware } from '@nuxt/types'
import { FromPage } from '~/types'

const domain: Middleware = (context) => {
  if (context.req) {
    const currentHost = context.req.headers.host
    const atSchoolAccessRoute = [
      'sign-in',
      'sign-up',
      'change-password',
      'schoolSlug',
      'schoolSlug-courseSlug-referenceId',
      'schoolSlug-courseSlug',
      'shopping-cart',
      'notify',
      'conversation',
      'schedule-list',
      'schedule-calendar',
      'payment',
      'payment-courseInstanceId',
      'payment-add-child',
      'payment-payment-successful',
      'profile-children-list',
      'profile-payments',
      'profile-your-course',
      'profile-personal-information',
      'profile-config-notify',
      'profile-bookmarked-courses',
      'profile-children-list',
      'invoice-invoiceId',
      'terms-of-business',
      'terms-and-conditions',
      'privacy',
      'course-policy',
      'faqs'
    ]

    if (currentHost === process.env.PARENT_DEFAULT_HOST) {
      context.store.commit('setCurrentViewFor', FromPage.Agora)
    } else if (currentHost === process.env.AT_SCHOOL_HOST) {
      context.store.commit('setCurrentViewFor', FromPage.AtSchool)
      if (!atSchoolAccessRoute.includes(context.route.name!)) {
        context.redirect({ path: '/' })
      }
    }
  }
}

export default domain
