import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.MIXPANEL_TOKEN!, {
  debug: process.env.NODE_ENV === 'local',
  ignore_dnt: true,
  persistence: 'localStorage',
  track_pageview: true
})

export default mixpanel
